import React, { useState, useEffect } from 'react';
import { Editor, EditorState, RichUtils, Modifier, convertFromRaw, ContentState, SelectionState } from 'draft-js';
import { db } from '../firebaseConfig';
import { collection, getDoc, serverTimestamp, getDocs, query, where, orderBy, updateDoc, doc, setDoc } from 'firebase/firestore';
import { useSelector } from 'react-redux';
import 'draft-js/dist/Draft.css';
import './QuizAdd.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { getFirestore, addDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getApp } from 'firebase/app';

const QuizAdd = () => {
  const location = useLocation();

  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState('');
  const [categories, setCategories] = useState([]);
  const [selected1stCategory, setSelected1stCategory] = useState('');
  const [selected2ndCategory, setSelected2ndCategory] = useState('');
  const [selected3rdCategory, setSelected3rdCategory] = useState('');
  const [subCategories, setSubCategories] = useState([]);
  const [subSubCategories, setSubSubCategories] = useState([]);
  const [quizType, setQuizType] = useState('a');
  const [questionEditorState, setQuestionEditorState] = useState(EditorState.createEmpty());
  const [explanationEditorState, setExplanationEditorState] = useState(EditorState.createEmpty());
  const [savedQuestion, setSavedQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [comparison, setComparison] = useState('');
  const [tag, setTag] = useState('');
  const [premise, setPremise] = useState('');
  const [options, setOptions] = useState(['', '', '', '', '']);
  const [dTypeAnswers, setDTypeAnswers] = useState([]);
  const user = useSelector((state) => state.auth.user);
  const [num, setNum] = useState('');
  const [errors, setErrors] = useState({});
  const [comparisonEditorState, setComparisonEditorState] = useState(EditorState.createEmpty());
  const [selected1stCategoryName, setSelected1stCategoryName] = useState('');
  const [selected2ndCategoryName, setSelected2ndCategoryName] = useState('');
  const [selected3rdCategoryName, setSelected3rdCategoryName] = useState('');
  const { quizId } = useParams();
  const { quiz } = location.state || {};
  const isEditing = location.state?.isEditing || false;
  const [questionUnderlines, setQuestionUnderlines] = useState([]);
  const [explanationUnderlines, setExplanationUnderlines] = useState([]);
  const [comparisonUnderlines, setComparisonUnderlines] = useState([]);
  const [focusedEditor, setFocusedEditor] = useState('question'); // Track focused editor
  const [skipTag, setSkipTag] = useState(false);


  const [focusedElement, setFocusedElement] = useState('question'); // Track focused element

  const autoResizeTextarea = (e) => {
    e.target.style.height = '10px';
    e.target.style.height = (e.target.scrollHeight) + 'px';
  };
  const navigate = useNavigate();
  useEffect(() => {
    const handleKeyDown = (e) => {
      // Check if both Alt and Control keys are pressed
      if (e.altKey || e.ctrlKey) {
        let char;
        switch (e.key) {
          case '1':
            char = '①';
            break;
          case '2':
            char = '②';
            break;
          case '3':
            char = '③';
            break;
          case '4':
            char = '④';
            break;
          case '5':
            char = '⑤';
            break;
          case '6':
            char = '『';
            break;
          case '7':
            char = '』';
            break;
          case '8':
            char = '「';
            break;
          case '9':
            char = '」';
            break;
          case 'a':
            char = '甲';
            break;
          case 's':
            char = '乙';
            break;
          case 'd':
            char = '丙';
            break;
          case 'f':
            char = '丁';
            break;
          case 'z':
            char = '·';
            break;
          default:
            return; // If no matching key, exit
        }
        e.preventDefault();
  
        const activeElement = document.activeElement;
  
        // Case 1: For input fields and text areas
        if (activeElement && (activeElement.tagName === 'INPUT' || activeElement.tagName === 'TEXTAREA')) {
          const start = activeElement.selectionStart;
          const end = activeElement.selectionEnd;
          const value = activeElement.value;
  
          // Insert the special character at the current cursor position
          activeElement.value = value.slice(0, start) + char + value.slice(end);
          activeElement.selectionStart = activeElement.selectionEnd = start + char.length;
          return;
        }
  
        // Case 2: Trigger button click for the editor if no input field is focused
        const button = document.querySelector(`.special-char-button[data-char="${char}"]`);
        if (button) {
          button.click(); // Simulate a button click
        }
      }
    };
  
    window.addEventListener('keydown', handleKeyDown);
  
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []); // Empty dependency array to only run on mount

  const insertSpecialChar = (char) => {
    if (focusedElement === 'premise') {
      const textarea = document.getElementById('premise');
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      const newValue = premise.slice(0, start) + char + premise.slice(end);
      setPremise(newValue);
    }
       else {
      let editorState, setEditorState;
      switch (focusedElement) {
        case 'question':
          editorState = questionEditorState;
          setEditorState = setQuestionEditorState;
          break;
        case 'explanation':
          editorState = explanationEditorState;
          setEditorState = setExplanationEditorState;
          break;
        case 'comparison':
          editorState = comparisonEditorState;
          setEditorState = setComparisonEditorState;
          break;
        default:
          return;
      }
  
      const selection = editorState.getSelection();
      const content = editorState.getCurrentContent();
      let newContent;
      if (selection.isCollapsed()) {
        // 커서가 한 지점에 있을 때
        newContent = Modifier.insertText(content, selection, char + '');
      } else {
        // 텍스트가 선택되어 있을 때
        newContent = Modifier.replaceText(content, selection, char + '');
      }
      const newEditorState = EditorState.push(editorState, newContent, 'insert-characters');
      setEditorState(EditorState.forceSelection(newEditorState, newContent.getSelectionAfter()));
    }
  };
  

  
  useEffect(() => {
    fetchSubjects();
  }, []);

  useEffect(() => {
    if (selectedSubject) {
      fetchCategories();
    }
  }, [selectedSubject]);

  useEffect(() => {
    if (selected1stCategory) {
      fetchSubCategories(selected1stCategory);
    } else {
      setSubCategories([]);
      setSelected2ndCategory('');
      setSubSubCategories([]);
      setSelected3rdCategory('');
    }
  }, [selected1stCategory]);

  useEffect(() => {
    if (selected2ndCategory) {
      fetchSubSubCategories(selected2ndCategory);
    }
  }, [selected2ndCategory]);

  useEffect(() => {
    if (isEditing && quiz && categories.length > 0) {
      populateFormForEditing(quiz);
    }
  }, [isEditing, quiz, categories]);


  const replaceSpecialChars = (text) => {
    return text.replace(/•/g, '·');
  };
  
  

  // Function to insert special character into the focused element
  
  const fetchSubjects = async () => {
    const querySnapshot = await getDocs(query(collection(db, 'subjects'), orderBy('name', 'asc')));
    const subjectsData = querySnapshot.docs.map(doc => ({
      id: doc.id,
      name: doc.data().name
    }));
    setSubjects(subjectsData);
  };

  const fetchCategories = async () => {
    const querySnapshot = await getDocs(query(collection(db, `subjects/${selectedSubject}/1stCategories`), orderBy('createdAt', 'asc')));
    const categoriesData = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setCategories(categoriesData);
  };

  const fetchSubCategories = async (categoryId) => {
    const q = query(collection(db, `subjects/${selectedSubject}/1stCategories/${categoryId}/2ndCategories`), orderBy('createdAt', 'asc'));
    const querySnapshot = await getDocs(q);
    const subCategoriesData = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setSubCategories(subCategoriesData);
    if (subCategoriesData.length === 0) {
      setSelected2ndCategory('');
      setSelected3rdCategory('');
    }
  };
  
  const fetchSubSubCategories = async (categoryId) => {
    if (!selected1stCategory || !categoryId) return;
    const q = query(collection(db, `subjects/${selectedSubject}/1stCategories/${selected1stCategory}/2ndCategories/${categoryId}/3rdCategories`), orderBy('createdAt', 'asc'));
    const querySnapshot = await getDocs(q);
    const subSubCategoriesData = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setSubSubCategories(subSubCategoriesData);
  };

  const validateQuiz = () => {
    let isValid = true;
    let newErrors = {};

    if (!selectedSubject) {
      isValid = false;
      newErrors.selectedSubject = '과목을 선택해주세요';
    }
    if (!selected1stCategory) {
      isValid = false;
      newErrors.selected1stCategory = '카테고리를 선택해주세요';
    }
    
    if (subCategories.length > 0 && !selected2ndCategory) {
      isValid = false;
      newErrors.selected2ndCategory = '카테고리를 선택해주세요';
    }
    

    if (!quizType) {
      isValid = false;
      newErrors.quizType = '문제 유형을 선택해주세요';
    }
    if (!questionEditorState.getCurrentContent().hasText()) {
      isValid = false;
      newErrors.question = '문제를 입력해주세요';
    }
    if (!explanationEditorState.getCurrentContent().hasText()) {
      isValid = false;
      newErrors.explanation = '설명을 입력해주세요';
    }
    if (!skipTag && !tag) {
      isValid = false;
      newErrors.tag = '태그를 입력해주세요';
    }
    if (quizType === 'a' || quizType === 'b') {
      if (answer !== true && answer !== false) {
        isValid = false;
        newErrors.answer = 'O 또는 X를 선택해주세요';
      }
    }
    setErrors(newErrors);
    return isValid;
  };

  const setBlank = () => {
    const selectionState = questionEditorState.getSelection();
    const start = selectionState.getStartOffset();
    const end = selectionState.getEndOffset();
    const selectedText = questionEditorState.getCurrentContent().getPlainText().slice(start, end);

    if (start !== end) {
      const newDTypeAnswer = { start, end, text: selectedText };
      setDTypeAnswers([...dTypeAnswers, newDTypeAnswer]);

      const contentState = Modifier.replaceText(
        questionEditorState.getCurrentContent(),
        selectionState,
        '_'.repeat(end - start)
      );

      const newEditorState = EditorState.push(questionEditorState, contentState, 'insert-characters');
      setQuestionEditorState(newEditorState);
    }
  };
  const handlePremiseChange = (e) => {
    setPremise(e.target.value);
  };
  const createEditorState = (content, underlines) => {
    let contentState = ContentState.createFromText(content);
    let editorState = EditorState.createWithContent(contentState);

    if (underlines && underlines.length > 0) {
      underlines.forEach(underline => {
        const blockMap = contentState.getBlockMap();
        const block = blockMap.get(underline.blockKey);

        if (block) {
          const selection = SelectionState.createEmpty(underline.blockKey).merge({
            anchorOffset: underline.start,
            focusOffset: underline.end,
          });
          contentState = Modifier.applyInlineStyle(contentState, selection, 'UNDERLINE');
          editorState = EditorState.push(editorState, contentState, 'change-inline-style');
        }
      });
    }

    return editorState;
  };

  const populateFormForEditing = async (quiz) => {
    // ... (existing populateFormForEditing logic)
  };

  const handleAddQuiz = async () => {
    if (!validateQuiz()) {
      return;
    }

    let quizNum = num ? parseInt(num) : null;

    const quizzesRef = collection(db, `subjects/${selectedSubject}/quizzes`);
    const q = query(quizzesRef, where('category', '==', selected3rdCategory || selected2ndCategory || selected1stCategory), orderBy('num', 'asc'));
    const querySnapshot = await getDocs(q);
    const existingQuizzes = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    const categoryData = selected3rdCategory || selected2ndCategory || selected1stCategory || 'default';

    if (!quizNum) {
      quizNum = existingQuizzes.length > 0 ? existingQuizzes[existingQuizzes.length - 1].num + 1 : 1;
    } else {
      const conflictingQuizzes = existingQuizzes.filter(quiz => quiz.num >= quizNum);
      for (let quiz of conflictingQuizzes) {
        await updateDoc(doc(db, `subjects/${selectedSubject}/quizzes`, quiz.id), { num: quiz.num + 1 });
      }
    }

    let categoryPath = selected1stCategoryName;
    if (subCategories.length > 0 && selected2ndCategoryName) {
      categoryPath += ` - ${selected2ndCategoryName}`;
      if (selected3rdCategoryName) {
        categoryPath += ` - ${selected3rdCategoryName}`;
      }
    }

    let quizData = {
      question: replaceSpecialChars(questionEditorState.getCurrentContent().getPlainText()),
      answer: quizType === 'a' || quizType === 'b' ? answer === true : answer,
      explanation: replaceSpecialChars(explanationEditorState.getCurrentContent().getPlainText()),
      comparison: replaceSpecialChars(comparisonEditorState.getCurrentContent().getPlainText()),
      tag: skipTag ? '' : replaceSpecialChars(tag),
      createdAt: serverTimestamp(),
      createdBy: user?.uid,
      category: subCategories.length > 0 ? (selected3rdCategory || selected2ndCategory || selected1stCategory) : selected1stCategory,
      type: quizType,
      num: quizNum,
      underlineSelections: {
        question: getUnderlines(questionEditorState),
        explanation: getUnderlines(explanationEditorState)
      },
      categoryPath: replaceSpecialChars(categoryPath)
    };

    if (quizType === 'b') {
      quizData.premise = replaceSpecialChars(premise);
    } else if (quizType === 'c') {
      quizData.options = options.map(replaceSpecialChars);
    } else if (quizType === 'd') {
      quizData.answer = dTypeAnswers.map(answer => ({
        ...answer,
        text: replaceSpecialChars(answer.text)
      }));
    }

    let quizId;
    if (isEditing && quiz) {
      await updateDoc(doc(db, `subjects/${selectedSubject}/quizzes`, quiz.id), quizData);
      quizId = quiz.id;
      alert('문제가 성공적으로 수정되었습니다!');
    } else {
      const docRef = await addDoc(collection(db, `subjects/${selectedSubject}/quizzes`), quizData);
      quizId = docRef.id;
      alert('문제가 성공적으로 추가되었습니다!');
    }

    // Prepare embedding text
    const embeddingText = `
      Question: ${quizData.question}
      Answer: ${quizData.answer}
      Explanation: ${quizData.explanation}
      Comparison: ${quizData.comparison}
      Tag: ${quizData.tag}
      Category: ${quizData.categoryPath}
    `;

    // Call the createEmbeddingAndStore function
    try {
      const functions = getFunctions(getApp());
      const createEmbeddingAndStore = httpsCallable(functions, 'createEmbeddingAndStore');
      const result = await createEmbeddingAndStore({
        uid: quizId,
        embeddingText: embeddingText,
        subjectName: selectedSubject
      });
    
      if (result.data.success) {
        console.log('Embedding created and stored successfully');
      } else {
        console.error('Failed to create and store embedding');
      }
    } catch (error) {
      console.error('Error calling createEmbeddingAndStore:', error);
    }
    
    // Reset form fields
    setQuestionEditorState(EditorState.createEmpty());
    setSavedQuestion('');
    setAnswer();
    if (quizType !== 'b') {
      setTag(''); // Only reset tag if quiz type is not B
    }
    setOptions(['', '', '', '', '']);
    setDTypeAnswers([]);
    setNum('');

    if (isEditing) {
      navigate(-1);
    }
  };

  const handleOptionChange = (index, value) => {
    const newOptions = [...options];
    newOptions[index] = value;
    setOptions(newOptions);
  };

  const handleDTypeAnswerChange = (index, field, value) => {
    const newDTypeAnswers = [...dTypeAnswers];
    newDTypeAnswers[index] = { ...newDTypeAnswers[index], [field]: value };
    setDTypeAnswers(newDTypeAnswers);
  };

  const handleEditorChange = (editorState, setEditorState) => {
    setEditorState(editorState);
  };

  const toggleUnderline = (editorState, setEditorState) => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, 'UNDERLINE'));
  };

  const getUnderlines = (editorState) => {
    const contentState = editorState.getCurrentContent();
    const blocks = contentState.getBlocksAsArray();
    let underlines = [];
    let currentUnderline = null;
    let absoluteOffset = 0;

    blocks.forEach((block, blockIndex) => {
      const text = block.getText();
      const characterList = block.getCharacterList();

      characterList.forEach((char, index) => {
        if (char.hasStyle('UNDERLINE')) {
          if (!currentUnderline) {
            currentUnderline = { start: absoluteOffset + index, text: text[index] };
          } else {
            currentUnderline.text += text[index];
          }
        } else if (currentUnderline) {
          currentUnderline.end = absoluteOffset + index;
          underlines.push(currentUnderline);
          currentUnderline = null;
        }
      });

      if (currentUnderline) {
        currentUnderline.end = absoluteOffset + text.length;
        underlines.push(currentUnderline);
        currentUnderline = null;
      }

      absoluteOffset += text.length + 1; // +1 for the newline character
    });

    return underlines;
  };

  return (
    <div className="quiz-management">
      <h1>{isEditing ? '문제 수정' : '문제 추가'}</h1>
      {isEditing && (<h3>카테고리 다시 반영해야함 || 밑줄 다시 설정해야함</h3>)}
      <div className="form-group">
        <label>과목 선택</label>
        <select
          value={selectedSubject}
          onChange={(e) => setSelectedSubject(e.target.value)}
          className={errors.selectedSubject ? 'error' : ''}
        >
          <option value="">과목 선택</option>
          {subjects.map((subject) => (
            <option key={subject.id} value={subject.id}>{subject.name}</option>
          ))}
        </select>
        {errors.selectedSubject && <span className="error-message">{errors.selectedSubject}</span>}
      </div>
      <div className="form-group">
      <label>1차 카테고리 선택</label>
      <select
        value={selected1stCategory}
        onChange={(e) => {
          const selectedOption = e.target.options[e.target.selectedIndex];
          setSelected1stCategory(e.target.value);
          setSelected1stCategoryName(selectedOption.text);
          setSelected2ndCategory('');
          setSelected3rdCategory('');
          fetchSubCategories(e.target.value);
        }}
        className={errors.selected1stCategory ? 'error' : ''}
      >
        <option value="">카테고리 선택</option>
        {categories.map((category) => (
          <option key={category.id} value={category.id}>{category.name}</option>
        ))}
      </select>
      {errors.selected1stCategory && <span className="error-message">{errors.selected1stCategory}</span>}
    </div>
    
    {selected1stCategory && subCategories.length > 0 && (
  <div className="form-group">
    <label>2차 카테고리 선택</label>
    <select
      value={selected2ndCategory}
      onChange={(e) => {
        const selectedOption = e.target.options[e.target.selectedIndex];
        setSelected2ndCategory(e.target.value);
        setSelected2ndCategoryName(selectedOption.text);
        setSelected3rdCategory('');
        fetchSubSubCategories(e.target.value);
      }}
      className={errors.selected2ndCategory ? 'error' : ''}
    >
      <option value="">카테고리 선택</option>
      {subCategories.map((category) => (
        <option key={category.id} value={category.id}>{category.name}</option>
      ))}
    </select>
    {errors.selected2ndCategory && <span className="error-message">{errors.selected2ndCategory}</span>}
  </div>
)}
    
    {selected2ndCategory && subSubCategories.length > 0 && (
      <div className="form-group">
        <label>3차 카테고리 선택</label>
        <select
          value={selected3rdCategory}
          onChange={(e) => {
            const selectedOption = e.target.options[e.target.selectedIndex];
            setSelected3rdCategory(e.target.value);
            setSelected3rdCategoryName(selectedOption.text);
          }}
          className={errors.selected3rdCategory ? 'error' : ''}
        >
          <option value="">카테고리 선택</option>
          {subSubCategories.map((category) => (
            <option key={category.id} value={category.id}>{category.name}</option>
          ))}
        </select>
        {errors.selected3rdCategory && <span className="error-message">{errors.selected3rdCategory}</span>}
      </div>
    )}
        {selected1stCategory && subCategories.length === 0 && (
        <div className="form-group">
          <label>* 하위 카테고리 없음</label>
        </div>
      )}
      {selected2ndCategory && subSubCategories.length === 0 && (
        <div className="form-group">
          <label>* 하위 카테고리 없음</label>
        </div>
      )}
      <div className="form-group">
        <label>문제 유형 선택</label>
        <select
          value={quizType}
          onChange={(e) => setQuizType(e.target.value)}
          className={errors.quizType ? 'error' : ''}
        >
          <option value="a">Type A (True/False)</option>
          <option value="b">Type B (Premise + True/False)</option>
          <option value="c">Type C (Multiple Choice)</option>
          <option value="d">Type D (Fill in the Blank)</option>
        </select>
        {errors.quizType && <span className="error-message">{errors.quizType}</span>}
      </div>
      {quizType === 'b' && (
        <div className="form-group">
          <label>기본 지문</label>
          <textarea
            id="premise"
            value={premise}
            onChange={handlePremiseChange}
            onBlur={() => setFocusedElement(null)}
            onFocus={() => setFocusedElement('premise')}
          />


        </div>
      )}
      <div className="form-group">
        <label>문제</label>
        <div className="editor-container">
          <Editor
            editorState={questionEditorState}
            onChange={(editorState) => handleEditorChange(editorState, setQuestionEditorState)}
            onFocus={() => setFocusedElement('question')}

          />
          {quizType === 'd' && (
            <button onClick={setBlank}>빈칸설정</button>
          )}
          <button onClick={() => toggleUnderline(questionEditorState, setQuestionEditorState)}>밑줄 설정</button>
        </div>
        {errors.question && <span className="error-message">{errors.question}</span>}
      </div>
      {quizType === 'd' && (
        <div className="form-group">
          <label>미리보기</label>
          <p>{questionEditorState.getCurrentContent().getPlainText()}</p>
        </div>
      )}
{quizType === 'c' && (
  <div className="form-group">
    <label>보기</label>
    {options.map((option, index) => (
      <textarea
        key={index}
        value={option}
        onChange={(e) => {
          handleOptionChange(index, e.target.value);
          autoResizeTextarea(e);
        }}
        onInput={autoResizeTextarea}
        placeholder={`Option ${index + 1}`}
      />
    ))}
  </div>
)}


      {quizType === 'd' && (
        <div className="form-group">
          <label>정답 (빈칸 채우기)</label>
          {dTypeAnswers.map((answer, index) => (
            <div key={index} className="d-type-answer">
              <input
                type="text"
                value={answer.start}
                onChange={(e) => handleDTypeAnswerChange(index, 'start', e.target.value)}
                placeholder="Start"
                readOnly
              />
              <input
                type="text"
                value={answer.end}
                onChange={(e) => handleDTypeAnswerChange(index, 'end', e.target.value)}
                placeholder="End"
                readOnly
              />
              <input
                type="text"
                value={answer.text}
                onChange={(e) => handleDTypeAnswerChange(index, 'text', e.target.value)}
                placeholder="Text"
                readOnly
              />
            </div>
          ))}
        </div>
      )}
      <div className="form-group">
        {quizType === 'a' || quizType === 'b' ? (
          <div className="true-false-buttons">
            <button
              className={`true-false-button ${answer === true ? 'selected' : ''}`}
              onClick={() => setAnswer(true)}
            >
              O
            </button>
            <button
              className={`true-false-button ${answer === false ? 'selected' : ''}`}
              onClick={() => setAnswer(false)}
            >
              X
            </button>
          </div>
        ) : quizType === 'c' ? (
          <select value={answer} onChange={(e) => setAnswer(e.target.value)}>
            <option value="">정답 선택</option>
            {options.map((_, index) => (
              <option key={index} value={(index + 1).toString()}>{index + 1}</option>
            ))}
          </select>
        ) : null}
        {errors.answer && <span className="error-message">{errors.answer}</span>}
      </div>


      <div className="special-char-buttons">
      {['①', '②', '③', '④', '⑤', '甲', '乙', '丙', '丁', '·', '『', '』', '「', '」'].map((char) => (
      <button
        key={char}
        className="special-char-button"
        data-char={char} // Add data attribute for easy query
        onClick={() => insertSpecialChar(char)}
      >
        {char}
      </button>
    ))}

      </div>


      <div className="form-group">
        <label>설명</label>
        <div className="editor-container">
          <Editor
            editorState={explanationEditorState}
            onChange={(editorState) => handleEditorChange(editorState, setExplanationEditorState)}
            onFocus={() => setFocusedElement('explanation')}

          />
          <button onClick={() => toggleUnderline(explanationEditorState, setExplanationEditorState)}>밑줄 설정</button>
        </div>
        {errors.explanation && <span className="error-message">{errors.explanation}</span>}
      </div>
      <div className="form-group">
  <label>비교 (선택사항)</label>
  <div
  className="editor-container"
  style={{
    backgroundColor: 'transparent',
    minHeight: comparisonEditorState.getCurrentContent().hasText() ? '10px' : '40px',
    height: comparisonEditorState.getCurrentContent().hasText() ? 'auto' : '40px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    transition: 'all 0.3s ease',
    padding: '-10px',
    pointerEvents: comparisonEditorState.getCurrentContent().hasText() ? 'auto' : 'auto', // 초기 값으로 설정
    zIndex: 1 // 필요시 요소의 레이어를 변경해 보세요
  }}
>
  <Editor
    editorState={comparisonEditorState}
    onChange={(editorState) => handleEditorChange(editorState, setComparisonEditorState)}
    onFocus={() => setFocusedElement('comparison')}
  />
  {comparisonEditorState.getCurrentContent().hasText() && (
    <button onClick={() => toggleUnderline(comparisonEditorState, setComparisonEditorState)}>밑줄 설정</button>
  )}
</div>


</div>


<div className="form-group">
  <label>태그</label>
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <input
      type="text"
      value={tag}
      onChange={(e) => setTag(e.target.value)}
      onFocus={() => setFocusedElement('tag')}
      className={errors.tag ? 'error' : ''}
      disabled={skipTag}
      style={{ marginRight: '20px'}}
    />
    <label style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', marginRight: '10px', marginTop: '5px' }}>
      <input
        type="checkbox"
        checked={skipTag}
        onChange={(e) => setSkipTag(e.target.checked)}
        style={{ marginRight: '5px' }}
      />
      공란
    </label>
  </div>
  {errors.tag && <span className="error-message">{errors.tag}</span>}
</div>


      <div className="form-group">
        <label>문제 번호 (선택사항)</label>
        <input
          type="number"
          value={num}
          onChange={(e) => setNum(e.target.value)}
          placeholder="자동 생성됩니다"
        />
      </div>
      <button onClick={handleAddQuiz}>{isEditing ? '문제 수정' : '문제 추가'}</button>
    </div>
  );
};

export default QuizAdd;
