import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { collection, doc, getDocs, addDoc, deleteDoc, updateDoc, serverTimestamp, getDoc, query, where, orderBy } from 'firebase/firestore';
import './CategoryManagement.css';

const CategoryManagement = () => {
  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [categories, setCategories] = useState([]);
  const [selected1stCategory, setSelected1stCategory] = useState(null);
  const [selected2ndCategory, setSelected2ndCategory] = useState(null);
  const [new1stCategoryName, setNew1stCategoryName] = useState('');
  const [new2ndCategoryName, setNew2ndCategoryName] = useState('');
  const [new3rdCategoryName, setNew3rdCategoryName] = useState('');
  const [editingCategory, setEditingCategory] = useState(null);
  const [editingCategoryName, setEditingCategoryName] = useState('');

  useEffect(() => {
    fetchSubjects();
  }, []);

  useEffect(() => {
    if (selectedSubject) {
      fetchCategories();
    }
  }, [selectedSubject]);

  const fetchSubjects = async () => {
    const querySnapshot = await getDocs(collection(db, 'subjects'));
    const subjectsData = querySnapshot.docs.map(doc => ({
      id: doc.id,
      name: doc.data().name
    }));
    setSubjects(subjectsData);
  };

  const fetchCategories = async () => {
    if (!selectedSubject) return;
    const querySnapshot = await getDocs(collection(db, `subjects/${selectedSubject.id}/1stCategories`));
    const categoriesData = await Promise.all(querySnapshot.docs.map(async (doc) => {
      const subCategoriesSnapshot = await getDocs(collection(db, `subjects/${selectedSubject.id}/1stCategories/${doc.id}/2ndCategories`));
      const subCategories = await Promise.all(subCategoriesSnapshot.docs.map(async (subDoc) => {
        const subSubCategoriesSnapshot = await getDocs(collection(db, `subjects/${selectedSubject.id}/1stCategories/${doc.id}/2ndCategories/${subDoc.id}/3rdCategories`));
        const subSubCategories = subSubCategoriesSnapshot.docs.map(subSubDoc => ({
          id: subSubDoc.id,
          ...subSubDoc.data(),
          createdAt: subSubDoc.data().createdAt?.toDate() || new Date()
        }));
        return {
          id: subDoc.id,
          ...subDoc.data(),
          subSubCategories: subSubCategories.sort((a, b) => (a.order ?? Infinity) - (b.order ?? Infinity) || a.createdAt - b.createdAt),
          createdAt: subDoc.data().createdAt?.toDate() || new Date()
        };
      }));
      return {
        id: doc.id,
        ...doc.data(),
        subCategories: subCategories.sort((a, b) => (a.order ?? Infinity) - (b.order ?? Infinity) || a.createdAt - b.createdAt),
        createdAt: doc.data().createdAt?.toDate() || new Date()
      };
    }));
    setCategories(categoriesData.sort((a, b) => (a.order ?? Infinity) - (b.order ?? Infinity) || a.createdAt - b.createdAt));
  };

  const handleAddCategory = async (level) => {
    if (!selectedSubject) return;
    let collectionRef;
    let newCategoryName;

    if (level === 1) {
      collectionRef = collection(db, `subjects/${selectedSubject.id}/1stCategories`);
      newCategoryName = new1stCategoryName;
    } else if (level === 2 && selected1stCategory) {
      collectionRef = collection(db, `subjects/${selectedSubject.id}/1stCategories/${selected1stCategory.id}/2ndCategories`);
      newCategoryName = new2ndCategoryName;
    } else if (level === 3 && selected2ndCategory) {
      collectionRef = collection(db, `subjects/${selectedSubject.id}/1stCategories/${selected1stCategory.id}/2ndCategories/${selected2ndCategory.id}/3rdCategories`);
      newCategoryName = new3rdCategoryName;
    } else {
      return;
    }

    if (!newCategoryName.trim()) return;

    // Check for duplicate category name
    const q = query(collectionRef, where("name", "==", newCategoryName));
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      alert('같은 이름의 카테고리가 이미 존재합니다.');
      return;
    }

    await addDoc(collectionRef, {
      name: newCategoryName,
      createdAt: serverTimestamp()
    });

    if (level === 1) {
      setNew1stCategoryName('');
    } else if (level === 2) {
      setNew2ndCategoryName('');
      setSelected1stCategory(await fetchCategoryById(selected1stCategory.id, 1));
    } else if (level === 3) {
      setNew3rdCategoryName('');
      setSelected2ndCategory(await fetchCategoryById(selected2ndCategory.id, 2));
    }

    fetchCategories();
  };

  const fetchCategoryById = async (categoryId, level) => {
    if (!selectedSubject) return null;
    if (level === 1) {
      const docRef = doc(db, `subjects/${selectedSubject.id}/1stCategories`, categoryId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const subCategoriesSnapshot = await getDocs(collection(db, `subjects/${selectedSubject.id}/1stCategories/${categoryId}/2ndCategories`));
        const subCategories = await Promise.all(subCategoriesSnapshot.docs.map(async (subDoc) => {
          const subSubCategoriesSnapshot = await getDocs(collection(db, `subjects/${selectedSubject.id}/1stCategories/${categoryId}/2ndCategories/${subDoc.id}/3rdCategories`));
          const subSubCategories = subSubCategoriesSnapshot.docs.map(subSubDoc => ({
            id: subSubDoc.id,
            ...subSubDoc.data(),
            createdAt: subSubDoc.data().createdAt?.toDate() || new Date()
          }));
          return {
            id: subDoc.id,
            ...subDoc.data(),
            subSubCategories: subSubCategories.sort((a, b) => a.createdAt - b.createdAt),
            createdAt: subDoc.data().createdAt?.toDate() || new Date()
          };
        }));
        return {
          id: docSnap.id,
          ...docSnap.data(),
          subCategories: subCategories.sort((a, b) => a.createdAt - b.createdAt),
          createdAt: docSnap.data().createdAt?.toDate() || new Date()
        };
      }
    } else if (level === 2 && selected1stCategory) {
      const docRef = doc(db, `subjects/${selectedSubject.id}/1stCategories/${selected1stCategory.id}/2ndCategories`, categoryId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const subSubCategoriesSnapshot = await getDocs(collection(db, `subjects/${selectedSubject.id}/1stCategories/${selected1stCategory.id}/2ndCategories/${categoryId}/3rdCategories`));
        const subSubCategories = subSubCategoriesSnapshot.docs.map(subSubDoc => ({
          id: subSubDoc.id,
          ...subSubDoc.data(),
          createdAt: subSubDoc.data().createdAt?.toDate() || new Date()
        }));
        return {
          id: docSnap.id,
          ...docSnap.data(),
          subSubCategories: subSubCategories.sort((a, b) => a.createdAt - b.createdAt),
          createdAt: docSnap.data().createdAt?.toDate() || new Date()
        };
      }
    }
    return null;
  };

  const handleDeleteCategory = async (level, categoryId) => {
    if (!selectedSubject) return;
    if (!window.confirm('정말로 이 카테고리를 삭제하시겠습니까?')) {
      return;
    }

    let docRef;
    if (level === 1) {
      docRef = doc(db, `subjects/${selectedSubject.id}/1stCategories`, categoryId);
    } else if (level === 2 && selected1stCategory) {
      docRef = doc(db, `subjects/${selectedSubject.id}/1stCategories/${selected1stCategory.id}/2ndCategories`, categoryId);
    } else if (level === 3 && selected2ndCategory) {
      docRef = doc(db, `subjects/${selectedSubject.id}/1stCategories/${selected1stCategory.id}/2ndCategories/${selected2ndCategory.id}/3rdCategories`, categoryId);
    } else {
      return;
    }

    await deleteDoc(docRef);
    fetchCategories();
  };

  const handleUpdateCategory = async (level, categoryId, newName) => {
    if (!selectedSubject) return;
    let docRef;
    if (level === 1) {
      docRef = doc(db, `subjects/${selectedSubject.id}/1stCategories`, categoryId);
    } else if (level === 2 && selected1stCategory) {
      docRef = doc(db, `subjects/${selectedSubject.id}/1stCategories/${selected1stCategory.id}/2ndCategories`, categoryId);
    } else if (level === 3 && selected2ndCategory) {
      docRef = doc(db, `subjects/${selectedSubject.id}/1stCategories/${selected1stCategory.id}/2ndCategories/${selected2ndCategory.id}/3rdCategories`, categoryId);
    } else {
      return;
    }

    await updateDoc(docRef, { name: newName });
    fetchCategories();
  };

  const startEditingCategory = (category) => {
    setEditingCategory(category);
    setEditingCategoryName(category.name);
  };

  const saveEditingCategory = async () => {
    if (editingCategory) {
      await handleUpdateCategory(editingCategory.level, editingCategory.id, editingCategoryName);
      setEditingCategory(null);
      setEditingCategoryName('');
    }
  };

  const renderCategories = (categories, level) => {
    return categories.map(category => (
      <div key={category.id} className="category-item">
        {editingCategory && editingCategory.id === category.id ? (
          <>
            <input
              type="text"
              value={editingCategoryName}
              onChange={(e) => setEditingCategoryName(e.target.value)}
              className="category-input"
            />
            <button onClick={saveEditingCategory} className="category-button">저장</button>
            <button onClick={() => setEditingCategory(null)} className="category-button">취소</button>
          </>
        ) : (
          <>
            <span>{category.name}</span>
            <button onClick={() => startEditingCategory({ ...category, level })} className="category-button">수정</button>
            <button onClick={() => handleDeleteCategory(level, category.id)} className="category-button">삭제</button>
            {level < 3 && (
              <button onClick={() => handleSelectCategory(category, level)} className="category-button">
                선택
              </button>
            )}
          </>
        )}
      </div>
    ));
  };

  const handleSelectCategory = async (category, level) => {
    if (level === 1) {
      setSelected1stCategory(category);
      setSelected2ndCategory(null);
    } else if (level === 2) {
      const updatedCategory = await fetchCategoryById(category.id, 2);
      setSelected2ndCategory(updatedCategory);
    }
  };

  return (
    <div className="category-management">
      <div className="category-container">
        <div className="category-level">
          <h2>과목 선택</h2>
          {subjects.map(subject => (
            <div key={subject.id} className="category-item">
              <span>{subject.name}</span>
              <button onClick={() => setSelectedSubject(subject)} className="category-button">선택</button>
            </div>
          ))}
        </div>
        {selectedSubject && (
          <>
            <div className="category-level">
              <h2>1차 카테고리 ({selectedSubject.name})</h2>
              {renderCategories(categories, 1)}
              <div className="add-category">
                <input
                  type="text"
                  value={new1stCategoryName}
                  onChange={(e) => setNew1stCategoryName(e.target.value)}
                  placeholder="새 카테고리 이름"
                  className="category-input"
                />
                <button onClick={() => handleAddCategory(1)} className="category-button">추가</button>
              </div>
            </div>
            {selected1stCategory && (
              <div className="category-level">
                <h2>2차 카테고리 (상위: {selected1stCategory.name})</h2>
                {renderCategories(selected1stCategory.subCategories || [], 2)}
                <div className="add-category">
                  <input
                    type="text"
                    value={new2ndCategoryName}
                    onChange={(e) => setNew2ndCategoryName(e.target.value)}
                    placeholder="새 카테고리 이름"
                    className="category-input"
                  />
                  <button onClick={() => handleAddCategory(2)} className="category-button">추가</button>
                </div>
              </div>
            )}
            {selected2ndCategory && (
              <div className="category-level">
                <h2>3차 카테고리 (상위: {selected2ndCategory.name})</h2>
                {renderCategories(selected2ndCategory.subSubCategories || [], 3)}
                <div className="add-category">
                  <input
                    type="text"
                    value={new3rdCategoryName}
                    onChange={(e) => setNew3rdCategoryName(e.target.value)}
                    placeholder="새 카테고리 이름"
                    className="category-input"
                  />
                  <button onClick={() => handleAddCategory(3)} className="category-button">추가</button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CategoryManagement;
