import React, { useState, useEffect } from 'react';
import { db, auth } from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { Line } from 'react-chartjs-2';
import 'chart.js/auto'; // Import Chart.js
import styles from './Profile.module.css';
import { useLocation } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';

const Profile = () => {
  const [userData, setUserData] = useState({});
  const [recentSolvedQuizzes, setRecentSolvedQuizzes] = useState([]);
  const [recentSavedQuizzes, setRecentSavedQuizzes] = useState([]);
  const [dailyActivity, setDailyActivity] = useState([]);
  const navigate = useNavigate(); // Initialize useNavigate
  const location = useLocation(); // useLocation 훅 사용


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchUserData(user);
      } else {
        // 사용자가 로그인하지 않은 경우 처리
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [location, navigate]);
  

  const fetchUserData = async (user) => {
    setUserData({
      name: user.displayName,
      email: user.email,
      photoURL: user.photoURL,
    });

    // Fetch overall data for recent quizzes
    const overallDataRef = doc(db, 'userData', user.uid, 'overallData', 'summary');
    const overallDataSnap = await getDoc(overallDataRef);
    if (overallDataSnap.exists()) {
      const overallData = overallDataSnap.data();
      setRecentSolvedQuizzes(overallData.recentSolvedQuizzes || []);
      setRecentSavedQuizzes(overallData.recentSavedQuizzes || []);
    }

    // Fetch daily activity data
    const today = new Date();
    const last14Days = Array.from({ length: 14 }, (_, i) => {
      const date = new Date(today);
      date.setDate(today.getDate() - i);
      return date.toISOString().split('T')[0];
    }).reverse();

    const activityData = [];
    for (const date of last14Days) {
      const dailyRef = doc(db, 'userData', user.uid, 'dailyActivity', date);
      const dailySnap = await getDoc(dailyRef);
      activityData.push({
        date,
        quizzesSolved: dailySnap.exists() ? dailySnap.data().quizzesSolved || 0 : 0,
      });
    }
    setDailyActivity(activityData);
  };






  const chartData = {
    labels: dailyActivity.map(activity => activity.date),
    datasets: [
      {
        label: 'Quizzes Solved',
        data: dailyActivity.map(activity => activity.quizzesSolved),
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1,
      },
    ],
  };

  const handleQuizClick = async (quizId) => {
    try {
      const user = auth.currentUser;
      if (!user) {
        console.error("User is not authenticated");
        return;
      }

      const userQuizRef = doc(db, 'userQuizStats', user.uid, 'quizzes', quizId);
      const userQuizSnap = await getDoc(userQuizRef);

      if (userQuizSnap.exists()) {
        const userData = userQuizSnap.data();
        const quizRef = doc(db, `subjects/${userData.subject}/quizzes`, quizId);
        const quizSnap = await getDoc(quizRef);

        if (quizSnap.exists()) {
          const quizData = quizSnap.data();
          navigate('/quiz-solving', {
            state: {
              selectedNums: [{ num: quizData.num, categoryId: quizData.category }],
              isSingleQuiz: true,
              fromSavedQuizzes: true,
              selectedSubject: userData.subject
            }
          });
        } else {
          console.error("Quiz not found:", quizId);
        }
      } else {
        console.error("User quiz data not found:", quizId);
      }
    } catch (error) {
      console.error("Error fetching quiz:", error);
    }
  };

  return (
    <div className={styles.profilePage}>
      <div className={styles.profileHeader}>
        <img src={userData.photoURL} alt="Profile" className={styles.profilePhoto} />
        <div className={styles.profileInfo}>
          <h2>{userData.name}</h2>
          <p>{userData.email}</p>
        </div>
      </div>
      <div className={styles.profileStats}>
        <h3>최근 활동</h3>
        <Line data={chartData} />
      </div>
      <div className={styles.quizLists}>
        <div className={styles.savedQuizzes}>
          <h3>
            저장된 문제
            <span
              className={styles.seeMoreLink}
              onClick={() => navigate('/saved')}
              style={{ cursor: 'pointer', marginLeft: '10px', fontSize: 'small' }}
            >
              더보기
            </span>
          </h3>
          <ul>
            {recentSavedQuizzes.map((quiz, index) => (
              <li
                key={index}
                className={styles.quizItem}
                onClick={() => handleQuizClick(quiz.quizId)}
                style={{ cursor: 'pointer' }}
              >
                {quiz.questionSnippet}
              </li>
            ))}
          </ul>
        </div>
        <div className={styles.recentQuizzes}>
          <h3>최근 푼 문제</h3>
          <ul>
            {recentSolvedQuizzes.map((quiz, index) => (
              <li
                key={index}
                className={styles.quizItem}
                onClick={() => handleQuizClick(quiz.quizId)}
                style={{ cursor: 'pointer' }}
              >
                {quiz.questionSnippet}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Profile;
