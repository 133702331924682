import React, { useEffect } from 'react';
import './Home.css'; // CSS 파일을 임포트합니다.
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Home = () => {
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {


    // Function to fetch the background image
    const fetchBackgroundImage = async () => {
      try {
        const response = await fetch('/splash.png'); // Update with the correct path to your background image
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Check if the response was fetched from the cache or fetched anew
        if (response.type === 'basic' || response.type === 'cors') {
          console.log('Background image was fetched from the cache.');
        } else if (response.type === 'default') {
          console.log('Background image was fetched anew.');
        }
      } catch (error) {
        console.error('Error fetching background image:', error);
      }
    };

    // Call the function to fetch the background image
    fetchBackgroundImage();
  }, []);

  return (
    <div className="home-container">
      <header className="home-header">
        <h1>New Splash</h1>
        <p>into Civil Law</p>
      </header>
      <main className="home-main">
        <section className="home-section">
          {user ? (
            <Link to="/subject-selecting">
              <button className="home-button">Start Splash</button>
            </Link>
          ) : (
            <Link to="/login">
              <button className="home-button">Start Splash</button>
            </Link>
          )}
        </section>
      </main>
      <footer className="home-footer">
        <p>&copy; 2024 New Splash. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default Home;
