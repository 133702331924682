import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { collection, getDocs, query, getDoc, doc, setDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';

const FillEmbeddings = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentProcessing, setCurrentProcessing] = useState(null);
  const [processedItems, setProcessedItems] = useState([]);
  const [quizzes, setQuizzes] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [retryCount, setRetryCount] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const [isTaskRunning, setIsTaskRunning] = useState(false);
  const [isFindingSimilar, setIsFindingSimilar] = useState(false);
  const [similarProgress, setSimilarProgress] = useState(0);
  const [similarProcessedItems, setSimilarProcessedItems] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState('');

  useEffect(() => {
    const fetchSubjects = async () => {
      const subjectsRef = collection(db, 'subjects');
      const subjectsSnapshot = await getDocs(query(subjectsRef));
      setSubjects(subjectsSnapshot.docs.map(doc => ({ id: doc.id, name: doc.data().name })));
    };
    fetchSubjects();
  }, []);

  useEffect(() => {
    const fetchQuizzes = async () => {
      if (selectedSubject) {
        const quizzesRef = collection(db, `subjects/${selectedSubject}/quizzes`);
        const quizzesSnapshot = await getDocs(query(quizzesRef));
        setQuizzes(quizzesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      }
    };
    fetchQuizzes();
  }, [selectedSubject]);

  useEffect(() => {
    let intervalId;
    if (isProcessing && !isPaused && currentIndex < quizzes.length && !isTaskRunning) {
      intervalId = setInterval(() => {
        if (!isTaskRunning) {
          processNextQuiz();
        }
      }, 100);
    }
    return () => clearInterval(intervalId);
  }, [isProcessing, isPaused, currentIndex, quizzes, isTaskRunning]);

  const toggleProcessing = () => {
    if (isProcessing) {
      setIsPaused(!isPaused);
    } else {
      startFillingEmbeddings();
    }
  };

  const startFillingEmbeddings = () => {
    setIsProcessing(true);
    setProgress(0);
    setProcessedItems([]);
    setCurrentIndex(0);
    setIsPaused(false);
  };

  const processNextQuiz = async () => {
    if (!isProcessing || isPaused || currentIndex >= quizzes.length) {
      setIsProcessing(false);
      setCurrentProcessing(null);
      return;
    }

    setIsTaskRunning(true);

    const quizData = quizzes[currentIndex];
    setCurrentProcessing(`Processing quiz #${quizData.num || 'N/A'}: ${quizData.question.substring(0, 30)}...`);

    try {
      const embeddingDoc = await getDoc(doc(db, `subjects/${selectedSubject}/embeddings`, quizData.id));
      if (embeddingDoc.exists()) {
        setRetryCount(0);
        setProcessedItems(prev => [...prev, { id: quizData.id, status: 'skipped' }]);
        setCurrentIndex(prevIndex => prevIndex + 1);
        setProgress(Math.min(((currentIndex + 1) / quizzes.length) * 100, 100));
        setIsTaskRunning(false);
        return;
      }

      const embeddingText = `
        Question: ${quizData.question}
        Answer: ${quizData.answer}
        Explanation: ${quizData.explanation}
        Comparison: ${quizData.comparison || ''}
        Tag: ${quizData.tag}
        Category: ${quizData.categoryPath}
      `;

      const functions = getFunctions();
      const createEmbeddingAndStore = httpsCallable(functions, 'createEmbeddingAndStore');
      await createEmbeddingAndStore({
        uid: quizData.id,
        embeddingText: embeddingText,
        subjectName: selectedSubject
      });

      setProcessedItems(prev => [...prev, { id: quizData.id, status: 'success' }]);
      setRetryCount(0);
    } catch (error) {
      console.error(`Error processing quiz ${quizData.id}:`, error);
      setProcessedItems(prev => [...prev, { id: quizData.id, status: `retry (${retryCount + 1})` }]);
      const retryDelay = 3000 + (retryCount * 2000);
      setRetryCount(prevCount => prevCount + 1);
      setTimeout(() => {
        setIsTaskRunning(false);
        processNextQuiz();
      }, 5000);
      return;
    }

    setCurrentIndex(prevIndex => prevIndex + 1);
    setProgress(Math.min(((currentIndex + 1) / quizzes.length) * 100, 100));
    setTimeout(() => {
      setIsTaskRunning(false);
      processNextQuiz();
    }, 20000);
  };

  const findAndStoreSimilarQuizzes = async () => {
    setIsFindingSimilar(true);
    setSimilarProgress(0);
    setSimilarProcessedItems([]);
    for (let i = 0; i < quizzes.length; i++) {
      const quiz = quizzes[i];
      setCurrentProcessing(`Processing similar quizzes for quiz #${quiz.num || 'N/A'}: ${quiz.question.substring(0, 30)}...`);

      const similarQuizzesDoc = await getDoc(doc(db, `subjects/${selectedSubject}/similarQuizzes`, quiz.id));
      if (similarQuizzesDoc.exists()) {
        setSimilarProcessedItems(prev => [...prev, { id: quiz.id, status: 'skipped' }]);
        setSimilarProgress(Math.min(((i + 1) / quizzes.length) * 100, 100));
        continue;
      }

      const functions = getFunctions();
      const findSimilarQuizzes = httpsCallable(functions, 'findSimilarQuizzes');
      try {
        const result = await findSimilarQuizzes({ quizId: quiz.id, topN: 10, subjectName: selectedSubject });
        const similarQuizIds = result.data.filter(id => id !== quiz.id);
        await setDoc(doc(db, `subjects/${selectedSubject}/similarQuizzes`, quiz.id), { similarQuizIds });
        setSimilarProcessedItems(prev => [...prev, { id: quiz.id, status: 'success' }]);
      } catch (error) {
        console.error(`Error finding similar quizzes for quiz ${quiz.id}:`, error);
        setSimilarProcessedItems(prev => [...prev, { id: quiz.id, status: 'failed' }]);
      }
      setSimilarProgress(Math.min(((i + 1) / quizzes.length) * 100, 100));
    }
    setIsFindingSimilar(false);
    setCurrentProcessing(null);
  };

  return (
    <div className="fill-embeddings">
      <h2>임베딩 채우기</h2>
      <div>
        <label>
          과목 선택:
          <select 
            value={selectedSubject} 
            onChange={(e) => setSelectedSubject(e.target.value)}
          >
            <option value="">과목을 선택하세요</option>
            {subjects.map(subject => (
              <option key={subject.id} value={subject.id}>{subject.id}</option>
            ))}
          </select>
        </label>
      </div>
      <button onClick={toggleProcessing} disabled={!selectedSubject}>
        {isProcessing ? (isPaused ? '재개' : '일시정지') : '시작'}
      </button>
      <button onClick={findAndStoreSimilarQuizzes} disabled={!selectedSubject}>유사문제 채우기</button>
      {isProcessing && (
        <div className="progress">
          <progress value={progress} max={100} />
          <p>{Math.round(progress)}% 완료 ({Math.round(progress)}/100)</p>
          {currentProcessing && <p>현재 처리 중: {currentProcessing}</p>}
        </div>
      )}
      {isFindingSimilar && (
        <div className="progress">
          <progress value={similarProgress} max={100} />
          <p>{Math.round(similarProgress)}% 유사 문제 찾기 완료 ({Math.round(similarProgress)}/100)</p>
          {currentProcessing && <p>현재 처리 중: {currentProcessing}</p>}
        </div>
      )}
      <div className="processed-items">
        <h3>처리된 항목:</h3>
        <ul>
          {processedItems.slice(-10).map((item, index) => (
            <li key={index}>
              Quiz ID: {item.id} - Status: {item.status === 'success' ? '성공' : item.status === 'skipped' ? '스킵' : '실패'}
            </li>
          ))}
        </ul>
      </div>
      <div className="similar-processed-items">
        <h3>유사 문제 처리된 항목:</h3>
        <ul>
          {similarProcessedItems.slice(-10).map((item, index) => (
            <li key={index}>
              Quiz ID: {item.id} - Status: {item.status === 'success' ? '성공' : item.status === 'skipped' ? '스킵' : '실패'}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default FillEmbeddings;
