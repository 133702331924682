import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { db, auth } from '../firebaseConfig';
import { collection, getDocs, query, where, doc, getDoc,orderBy } from 'firebase/firestore';
import './SavedQuizzes.css';
import LoadingSpinner from './LoadingSpinner'; // Import the LoadingSpinner component

const SavedQuizzes = () => {
  const [savedQuizzes, setSavedQuizzes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [subSubCategories, setSubSubCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [selectedSubCategory, setSelectedSubCategory] = useState('all');
  const [selectedSubSubCategory, setSelectedSubSubCategory] = useState('all');
  const navigate = useNavigate();

  const fetchCategories = async () => {
    const categoriesRef = collection(db, '1stCategories');
    const q = query(categoriesRef, orderBy('createdAt', 'asc'));
    const querySnapshot = await getDocs(q);
    setCategories([
      { id: 'all', name: 'All' },
      ...querySnapshot.docs.map(doc => ({ id: doc.id, name: doc.data().name }))
    ]);
  };

  const fetchSubCategories = async (categoryId) => {
    if (categoryId === 'all') {
      setSubCategories([]);
      return;
    }
    const subCategoriesRef = collection(db, `1stCategories/${categoryId}/2ndCategories`);
    const q = query(subCategoriesRef, orderBy('createdAt', 'asc'));
    const querySnapshot = await getDocs(q);
    const subCategoriesData = querySnapshot.docs.map(doc => ({ id: doc.id, name: doc.data().name }));
    setSubCategories(subCategoriesData.length > 0 ? [{ id: 'all', name: 'All' }, ...subCategoriesData] : []);
  };
  
  const fetchSubSubCategories = async (categoryId, subCategoryId) => {
    if (subCategoryId === 'all') {
      setSubSubCategories([]);
      return;
    }
    const subSubCategoriesRef = collection(db, `1stCategories/${categoryId}/2ndCategories/${subCategoryId}/3rdCategories`);
    const q = query(subSubCategoriesRef, orderBy('createdAt', 'asc'));
    const querySnapshot = await getDocs(q);
    const subSubCategoriesData = querySnapshot.docs.map(doc => ({ id: doc.id, name: doc.data().name }));
    setSubSubCategories(subSubCategoriesData.length > 0 ? [{ id: 'all', name: 'All' }, ...subSubCategoriesData] : []);
  };

  const fetchSavedQuizzes = useCallback(async () => {
    const user = auth.currentUser;
    if (!user) {
      console.log("No user logged in");
      setIsLoading(false);
      return;
    }
    
    try {
      console.log("Fetching saved quizzes for user:", user.uid);
      const userQuizStatsRef = collection(db, 'userQuizStats',user.uid,'quizzes');
      const q = query(userQuizStatsRef, 
        where('isSaved', '==', true)
      );
      const querySnapshot = await getDocs(q);
      
      if (!querySnapshot.empty) {
        const quizzes = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        
        setSavedQuizzes(quizzes);
      } else {
        setSavedQuizzes([]);
      }
    } catch (error) {
      console.error("Error fetching saved quizzes:", error);
      setSavedQuizzes([]);
    } finally {
      setIsLoading(false);
    }
  }, []);
  

  useEffect(() => {
    fetchCategories();
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchSavedQuizzes();
      } else {
        setIsLoading(false);
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [fetchSavedQuizzes, navigate]);

  useEffect(() => {
    if (selectedCategory !== 'all') {
      fetchSubCategories(selectedCategory);
    } else {
      setSubCategories([]);
      setSubSubCategories([]);
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (selectedSubCategory !== 'all' && selectedCategory !== 'all') {
      fetchSubSubCategories(selectedCategory, selectedSubCategory);
    } else {
      setSubSubCategories([]);
    }
  }, [selectedSubCategory, selectedCategory]);

  const handleQuizClick = async (quizId, index) => {
    try {
      const userQuizRef = doc(db, 'userQuizStats', auth.currentUser.uid, 'quizzes', quizId);
      const userQuizSnap = await getDoc(userQuizRef);
  
      if (userQuizSnap.exists()) {
        const userData = userQuizSnap.data();
        const quizRef = doc(db, `subjects/${userData.subject}/quizzes`, quizId);
        const quizSnap = await getDoc(quizRef);
  
        if (quizSnap.exists()) {
          const quizData = quizSnap.data();
          navigate('/quiz-solving', { 
            state: { 
              selectedNums: [{ num: quizData.num, categoryId: quizData.category }],
              isSingleQuiz: true,
              listingIndex: index,
              fromSavedQuizzes: true,
              selectedSubject: userData.subject
            } 
          });
        } else {
          console.error("Quiz not found:", quizId);
        }
      } else {
        console.error("User quiz data not found:", quizId);
      }
    } catch (error) {
      console.error("Error fetching quiz:", error);
    }
  };
  
  
  
  const filteredQuizzes = savedQuizzes.filter(quiz => {
    if (selectedCategory === 'all') {
      return true;
    }

    if (selectedSubCategory === 'all') {
      return quiz.categoryPath && quiz.categoryPath.includes(categories.find(cat => cat.id === selectedCategory)?.name);
    }

    if (selectedSubSubCategory === 'all') {
      return quiz.categoryPath && quiz.categoryPath.includes(subCategories.find(subCat => subCat.id === selectedSubCategory)?.name);
    }

    return quiz.categoryPath && quiz.categoryPath.includes(subSubCategories.find(subSubCat => subSubCat.id === selectedSubSubCategory)?.name);
  });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!auth.currentUser) {
    return <div className="loading-container">로그인이 필요합니다.</div>;
  }

  if (savedQuizzes.length === 0) {
    return <div className="loading-container">저장된 퀴즈가 없습니다.</div>;
  }
  
  return (
    <div className="saved-quizzes-container">
              <h1>저장된 문제</h1>
      <div className="navbar">
        {categories.map(category => (
          <button
            key={category.id}
            className={`nav-item ${selectedCategory === category.id ? 'selected' : ''}`}
            onClick={() => {
              setSelectedCategory(category.id);
              setSelectedSubCategory('all');
              setSelectedSubSubCategory('all');
            }}
          >
            {category.name}
          </button>
        ))}
      </div>
      {subCategories.length > 0 && (
        <div className="navbar">
          {subCategories.map(subCategory => (
            <button
              key={subCategory.id}
              className={`nav-item ${selectedSubCategory === subCategory.id ? 'selected' : ''}`}
              onClick={() => {
                setSelectedSubCategory(subCategory.id);
                setSelectedSubSubCategory('all');
              }}
            >
              {subCategory.name}
            </button>
          ))}
        </div>
      )}
      {subSubCategories.length > 0 && (
        <div className="navbar">
          {subSubCategories.map(subSubCategory => (
            <button
              key={subSubCategory.id}
              className={`nav-item ${selectedSubSubCategory === subSubCategory.id ? 'selected' : ''}`}
              onClick={() => setSelectedSubSubCategory(subSubCategory.id)}
            >
              {subSubCategory.name}
            </button>
          ))}
        </div>
      )}
      <ul className="quiz-list">
        {filteredQuizzes.map((quiz, index) => (
          <li key={index} className="quiz-card" onClick={() => handleQuizClick(quiz.quizId, index)}>
            <div className="quiz-info">
              <div className="quiz-number">{quiz.num ? quiz.num.toString().padStart(3, '0') : 'N/A'}</div>
              <div className="quiz-question">{quiz.question ? (quiz.question.length > 70 ? `${quiz.question.substring(0, 70)}...` : quiz.question) : 'No question available'}</div>
              <div className="quiz-category">{quiz.categoryPath || 'No category path'}</div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SavedQuizzes;
