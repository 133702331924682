import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { db, auth } from '../firebaseConfig';
import { collection, getDocs, query, where, orderBy, doc, getDoc, writeBatch } from 'firebase/firestore';
import './QuizSelecting.css';
import { calculateRecommendationScore } from './RecommendationCalculator';

const QuizSelecting = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [subCategories, setSubCategories] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const [subSubCategories, setSubSubCategories] = useState([]);
  const [selectedSubSubCategory, setSelectedSubSubCategory] = useState('');
  const [quizCount, setQuizCount] = useState(0);
  const [selectedQuizCount, setSelectedQuizCount] = useState(1);
  const [isRandom, setIsRandom] = useState(false);
  const [numRange, setNumRange] = useState([]);
  const [selectedNums, setSelectedNums] = useState([]);
  const [lastSolvedNums, setLastSolvedNums] = useState({});
  const navigate = useNavigate();
  const [categoryNames, setCategoryNames] = useState({});
  const userId = localStorage.getItem('userId');
  const [recommendedQuizzes, setRecommendedQuizzes] = useState([]);
  const [recommendationCount, setRecommendationCount] = useState(10);
  const [isGeneratingRecommendations, setIsGeneratingRecommendations] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [max, setMax] = useState(10);
  const [allSubCategories, setAllSubCategories] = useState({});
  const [allSubSubCategories, setAllSubSubCategories] = useState({});
  const [recommendationGenerationCount, setRecommendationGenerationCount] = useState(0);
  const location = useLocation();
  const selectedSubject = location.state?.subject;
  const [subjectName, setSubjectName] = useState('');
  const [subjects, setSubjects] = useState([]);


  useEffect(() => {
    if (selectedSubject) {
      fetchAllCategories();
    }
  }, [selectedSubject]);

  useEffect(() => {
    if (selectedSubject) {
      const selectedSubjectData = subjects.find(subject => subject.id === selectedSubject);
      if (selectedSubjectData) {
        setSubjectName(selectedSubjectData.name);
      }
    }
  }, [selectedSubject, subjects]);
  
const fetchSubjects = async () => {
  const subjectsCollection = collection(db, 'subjects');
  const subjectsSnapshot = await getDocs(subjectsCollection);
  const subjectsList = subjectsSnapshot.docs.map(doc => ({
    id: doc.id,
    name: doc.data().name
  }));
  setSubjects(subjectsList);
};
useEffect(() => {
  const fetchSubjects = async () => {
    const subjectsCollection = collection(db, 'subjects');
    const subjectsSnapshot = await getDocs(subjectsCollection);
    const subjectsList = subjectsSnapshot.docs.map(doc => ({
      id: doc.id,
      name: doc.data().name
    }));
    setSubjects(subjectsList);
  };

  fetchSubjects();
}, []);

const handleSubjectSelect = (subject) => {
  navigate('/quiz-selecting', { state: { subjectId: subject.id, subjectName: subject.name } });
};

  const fetchAllCategories = async () => {
    const categoriesRef = collection(db, `subjects/${selectedSubject}/1stCategories`);
    const categoriesSnapshot = await getDocs(query(categoriesRef, orderBy('createdAt', 'asc')));
    
    const allCategories = [{ id: 'all', name: 'All' }];
    const allSubCategories = {};
    const allSubSubCategories = {};
    const names = {};
    
    for (const categoryDoc of categoriesSnapshot.docs) {
      const categoryData = categoryDoc.data();
      allCategories.push({ id: categoryDoc.id, name: categoryData.name });
      names[categoryDoc.id] = categoryData.name;
      
      const subCategoriesRef = collection(db, `subjects/${selectedSubject}/1stCategories/${categoryDoc.id}/2ndCategories`);
      const subCategoriesSnapshot = await getDocs(query(subCategoriesRef, orderBy('createdAt', 'asc')));
      
      allSubCategories[categoryDoc.id] = [{ id: 'all', name: 'All' }];
      
      for (const subCategoryDoc of subCategoriesSnapshot.docs) {
        const subCategoryData = subCategoryDoc.data();
        allSubCategories[categoryDoc.id].push({ id: subCategoryDoc.id, name: subCategoryData.name });
        names[subCategoryDoc.id] = subCategoryData.name;
        
        const subSubCategoriesRef = collection(db, `subjects/${selectedSubject}/1stCategories/${categoryDoc.id}/2ndCategories/${subCategoryDoc.id}/3rdCategories`);
        const subSubCategoriesSnapshot = await getDocs(query(subSubCategoriesRef, orderBy('createdAt', 'asc')));
        
        allSubSubCategories[subCategoryDoc.id] = [{ id: 'all', name: 'All' }];
        subSubCategoriesSnapshot.docs.forEach(subSubCategoryDoc => {
          const subSubCategoryData = subSubCategoryDoc.data();
          allSubSubCategories[subCategoryDoc.id].push({ id: subSubCategoryDoc.id, name: subSubCategoryData.name });
          names[subSubCategoryDoc.id] = subSubCategoryData.name;
        });
      }
    }
    
    setCategories(allCategories);
    setAllSubCategories(allSubCategories);
    setAllSubSubCategories(allSubSubCategories);
    setCategoryNames(names);
  };

  useEffect(() => {
    if (selectedCategory && selectedCategory !== 'all') {
      setSubCategories(allSubCategories[selectedCategory] || []);
    } else {
      setSubCategories([]);
    }
  }, [selectedCategory, allSubCategories]);

  useEffect(() => {
    if (selectedSubCategory && selectedSubCategory !== 'all') {
      setSubSubCategories(allSubSubCategories[selectedSubCategory] || []);
    } else {
      setSubSubCategories([]);
    }
  }, [selectedSubCategory, allSubSubCategories]);

  useEffect(() => {
    if (selectedSubSubCategory || selectedSubCategory || selectedCategory) {
      fetchQuizInfo();
    }
  }, [selectedSubSubCategory, selectedSubCategory, selectedCategory]);

  const fetchQuizInfo = async () => {
    let q;
    if (selectedSubSubCategory === 'all') {
      const subSubCategoryIds = allSubSubCategories[selectedSubCategory].slice(1).map(cat => cat.id);
      q = query(collection(db, `subjects/${selectedSubject}/quizzes`), where('category', 'in', subSubCategoryIds), orderBy('num'));
    } else if (selectedSubCategory === 'all') {
      const subCategoryIds = allSubCategories[selectedCategory].slice(1).map(cat => cat.id);
      const subSubCategoryIds = subCategoryIds.flatMap(id => allSubSubCategories[id].slice(1).map(cat => cat.id));
      q = query(collection(db, `subjects/${selectedSubject}/quizzes`), where('category', 'in', [...subCategoryIds, ...subSubCategoryIds]), orderBy('num'));
    } else if (selectedCategory === 'all') {
      q = query(collection(db, `subjects/${selectedSubject}/quizzes`), orderBy('num'));
    } else {
      q = query(collection(db, `subjects/${selectedSubject}/quizzes`), where('category', '==', selectedSubSubCategory || selectedSubCategory || selectedCategory), orderBy('num'));
    }
    
    const querySnapshot = await getDocs(q);
    setQuizCount(querySnapshot.size);
    const nums = querySnapshot.docs.map(docSnap => ({
      num: docSnap.data().num,
      category: docSnap.data().category,
      categoryName: docSnap.data().category,
      uid: `${docSnap.data().category}_${docSnap.data().num}`
    }));
    setNumRange(nums);
    setMax(nums.length);
  };


  const fetchLastSolvedNums = async () => {
    const user = auth.currentUser;
    if (!user) return;

    const lastSolvedNums = {};
    const userCategoryStatsRef = collection(db, 'userCategoryStats');
    
    let categoryIds;
    if (selectedCategory === 'all') {
      categoryIds = categories.map(category => category.id);
    } else if (selectedSubCategory === 'all') {
      categoryIds = subCategories.map(category => category.id);
    } else if (selectedSubSubCategory === 'all') {
      categoryIds = subSubCategories.map(category => category.id);
    } else {
      categoryIds = [selectedCategory, selectedSubCategory, selectedSubSubCategory].filter(Boolean);
    }
    
    const q = query(userCategoryStatsRef, where('userId', '==', user.uid), where('categoryId', 'in', categoryIds));
    const querySnapshot = await getDocs(q);
    
    querySnapshot.forEach(doc => {
      const data = doc.data();
      lastSolvedNums[data.categoryId] = data.lastSolvedNum;
    });
    
    setLastSolvedNums(lastSolvedNums);
  };

  const generateRecommendations = async () => {
    if (recommendationGenerationCount >= 2) {
      console.log("추천 생성 횟수 초과");
      return;
    }
  
    setIsGeneratingRecommendations(true);
    try {
      const user = auth.currentUser;
      if (!user) {
        console.log("User not authenticated");
        setRecommendedQuizzes([]);
        return;
      }
  
      let userStats = {};
      const userStatsQuery = query(collection(db, 'userQuizStats', user.uid, 'quizzes'));
      const userStatsSnapshot = await getDocs(userStatsQuery);
      userStatsSnapshot.forEach(doc => {
        userStats[doc.id] = doc.data();
      });
  
      const scoredQuizzes = numRange.map(quiz => {
        const stats = userStats[quiz.uid] || {};
        const score = calculateRecommendationScore({
          solvedCount: stats.solvedCount || 0,
          dontKnowCount: stats.dontKnowCount || 0,
          wrongCount: stats.wrongCount || 0,
          correctCount: stats.correctCount || 0,
          lastSolved: stats.lastSolved || null
        });
        return { ...quiz, score };
      });
  
      scoredQuizzes.sort((a, b) => b.score - a.score);
      const recommendedQuizzes = scoredQuizzes.slice(0, recommendationCount);
      setRecommendedQuizzes(recommendedQuizzes);
  
      console.log("추천된 문제의 가중치:");
      recommendedQuizzes.forEach(quiz => {
        console.log(`문제 번호: ${quiz.num}, 카테고리: ${quiz.category}, 가중치: ${quiz.score}`);
      });
  
      setRecommendationGenerationCount(prevCount => prevCount + 1);
    } catch (error) {
      console.error("Error generating recommendations:", error);
      setRecommendedQuizzes([]);
    } finally {
      setIsGeneratingRecommendations(false);
    }
  };
  

  const handleStartQuiz = () => {
    let selectedQuizzes = recommendedQuizzes.length > 0 ? recommendedQuizzes : selectedNums;

    if (isRandom) {
      selectedQuizzes = selectedQuizzes.sort(() => Math.random() - 0.5);
    } else {
      selectedQuizzes = selectedQuizzes.sort((a, b) => a.num - b.num);
    }

    navigate('/quiz-solving', {
      state: {
        subject: selectedSubject,
        subjectName: subjectName,
        selectedSubject: selectedSubject,
        category: selectedCategory,
        subCategory: selectedSubCategory,
        subSubCategory: selectedSubSubCategory,
        quizCount: selectedQuizCount,
        isRandom: isRandom,
        selectedNums: selectedQuizzes.map(item => ({
          num: item.num,
          categoryId: item.category
        }))
      }
    });
  };

  const toggleSelectNum = (num, category) => {
    setSelectedNums(prevSelectedNums => {
      const newSelectedNums = prevSelectedNums.some(selected => selected.num === num && selected.category === category)
        ? prevSelectedNums.filter(selected => !(selected.num === num && selected.category === category))
        : [...prevSelectedNums, { num, category }];
      setSelectedQuizCount(newSelectedNums.length);
      return newSelectedNums;
    });
  };

  const handleQuizCountChange = (e) => {
    const newQuizCount = Math.min(Math.max(1, parseInt(e.target.value)), quizCount);
    setSelectedQuizCount(newQuizCount);
    setSelectedNums([]);
  };

  const handleSelectAllCategory = (categoryUID, categoryItems) => {
    setSelectedNums(prevSelectedNums => {
      const isAllSelected = categoryItems.every(item =>
        prevSelectedNums.some(selected => selected.num === item.num && selected.category === item.category)
      );

      if (isAllSelected) {
        const newSelectedNums = prevSelectedNums.filter(selected =>
          !categoryItems.some(item => item.num === selected.num && item.category === selected.category)
        );
        setSelectedQuizCount(newSelectedNums.length);
        return newSelectedNums;
      } else {
        const newSelectedNums = [
          ...prevSelectedNums,
          ...categoryItems.filter(item =>
            !prevSelectedNums.some(selected => selected.num === item.num && selected.category === item.category)
          )
        ];
        setSelectedQuizCount(newSelectedNums.length);
        return newSelectedNums;
      }
    });
  };

  const renderNumRange = () => {
    const categories = [...new Set(numRange.map(item => item.categoryName))];
    return categories.map((categoryUID, index) => {
      const categoryItems = numRange.filter(item => item.categoryName === categoryUID);
      const isAllSelected = categoryItems.every(item =>
        selectedNums.some(selected => selected.num === item.num && selected.category === item.category)
      );

      return (
        <div key={index} className="category-row">
          <div className="category-label">
            {categoryNames[categoryUID] || 'Loading...'}
          </div>
          <button
            className="category-select-all"
            onClick={() => handleSelectAllCategory(categoryUID, categoryItems)}
          >
            {isAllSelected ? '전체 해제' : '전체 선택'}
          </button>
          <div className="num-row">
            {categoryItems.map((item) => (
              <div
                key={item.uid}
                className={`num-item ${selectedNums.some(selected => selected.num === item.num && selected.category === item.category) ? 'selected' : ''}`}
                onClick={() => toggleSelectNum(item.num, item.category)}
              >
                <span className="num">{item.num}</span>
                {selectedNums.some(selected => selected.num === item.num && selected.category === item.category) && <div className="triangle"></div>}
                {lastSolvedNums[item.category] === item.num && <div className="triangle black"></div>}
              </div>
            ))}
          </div>
        </div>
      );
    });
  };

  const renderRecommendedQuizzes = () => {
    const categories = [...new Set(recommendedQuizzes.map(item => item.categoryName))];
    return categories.map((categoryUID, index) => {
      const categoryItems = recommendedQuizzes.filter(item => item.categoryName === categoryUID);
      return (
        <div key={index} className="category-row">
          <div className="category-label">
            {categoryNames[categoryUID] || 'Loading...'}
          </div>
          <div className="num-row">
            {categoryItems.map((item) => (
              <div
                key={item.uid}
                className="num-item selected"
              >
                <span className="num">{item.num}</span>
                <div className="triangle"></div>
                {lastSolvedNums[item.category] === item.num && <div className="triangle black"></div>}
              </div>
            ))}
          </div>
        </div>
      );
    });
  };

  return (
    <div className="quiz-selecting">
<h1>{'['+subjectName+']'}</h1>
<h3>단원 선택</h3>

<div className="navbar">
        {categories.map(category => (
          <button
            key={category.id}
            className={`nav-item ${selectedCategory === category.id ? 'selected' : ''}`}
            onClick={() => setSelectedCategory(category.id)}
          >
            {category.name}
          </button>
        ))}
      </div>
      {subCategories.length > 0 && (
        <>
          <div className="navbar">
            {subCategories.map(category => (
              <button
                key={category.id}
                className={`nav-item ${selectedSubCategory === category.id ? 'selected' : ''}`}
                onClick={() => setSelectedSubCategory(category.id)}
              >
                {category.name}
              </button>
            ))}
          </div>
        </>
      )}
      {subSubCategories.length > 0 && (
        <>
          <div className="navbar">
            {subSubCategories.map(category => (
              <button
                key={category.id}
                className={`nav-item ${selectedSubSubCategory === category.id ? 'selected' : ''}`}
                onClick={() => setSelectedSubSubCategory(category.id)}
              >
                {category.name}
              </button>
            ))}
          </div>
        </>
      )}
      <div className="recommended-quizzes">
        <div className="label">추천 문제</div>
        <div className="recommendation-controls">
          <label className='category-label'>
            추천 문제 수:
            <br />
            <input
              type="number"
              value={recommendationCount}
              onChange={(e) => {
                const maxCount = Math.min(10, max);
                setRecommendationCount(Math.max(1, Math.min(maxCount, parseInt(e.target.value))));
              }}
              min="1"
              max={Math.min(10, max)}
              defaultValue={Math.min(10, max)}
            />
          </label>
          <button 
            onClick={generateRecommendations} 
            disabled={isGeneratingRecommendations || recommendationGenerationCount >= 2}
          >
            {isGeneratingRecommendations ? '생성 중...' : 
            recommendationGenerationCount >= 2 ? '생성 횟수 초과' : '추천 문제 생성하기'}
          </button>
        </div>
      </div>
      {quizCount > 0 && (
        <div>
          {recommendedQuizzes.length > 0 ? (
            <div className="num-range">
              {renderRecommendedQuizzes()}
            </div>
          ) : (
            <div className="num-range">
              {renderNumRange()}
            </div>
          )}
          {!recommendedQuizzes.length > 0 && (
            <>
              <p>총 문제 수: {quizCount}</p>
              <div className="form-group">
                <label>풀 문제 수</label>
                <input
                  type="number"
                  min="1"
                  max={quizCount}
                  value={selectedQuizCount}
                  onChange={handleQuizCountChange}
                />
              </div>
              <div className="form-group">
                <label>문제 출제 방식</label>
                <div className="order-random-buttons">
                  <button
                    className={`order-random-button ${!isRandom ? 'selected' : ''}`}
                    onClick={() => setIsRandom(false)}
                  >
                    순서대로
                  </button>
                  <button
                    className={`order-random-button ${isRandom ? 'selected' : ''}`}
                    onClick={() => setIsRandom(true)}
                  >
                    랜덤으로
                  </button>
                </div>
              </div>
            </>
          )}
          <div className="form-group button-right">
            <button
              onClick={handleStartQuiz}
              disabled={!quizCount || isLoading}
            >
              문제 풀기
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuizSelecting;
