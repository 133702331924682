import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebaseConfig';
import { collection, getDocs, query, where, deleteDoc, doc, orderBy } from 'firebase/firestore';
import './QuizList.css';

const QuizList = () => {
  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState('');
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [subCategories, setSubCategories] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const [subSubCategories, setSubSubCategories] = useState([]);
  const [selectedSubSubCategory, setSelectedSubSubCategory] = useState('');
  const [quizzes, setQuizzes] = useState([]);
  const [sortOrder, setSortOrder] = useState('asc');
  const navigate = useNavigate();

  useEffect(() => {
    fetchSubjects();
  }, []);

  useEffect(() => {
    if (selectedSubject) {
      fetchCategories();
    }
  }, [selectedSubject]);

  useEffect(() => {
    if (selectedCategory) {
      fetchSubCategories();
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (selectedSubCategory) {
      fetchSubSubCategories();
    }
  }, [selectedSubCategory]);

  useEffect(() => {
    if (selectedSubSubCategory || selectedSubCategory || selectedCategory) {
      fetchQuizzes();
    }
  }, [selectedSubSubCategory, selectedSubCategory, selectedCategory, sortOrder]);

  const fetchSubjects = async () => {
    const subjectsRef = collection(db, 'subjects');
    const q = query(subjectsRef, orderBy('name', 'asc'));
    const querySnapshot = await getDocs(q);
    setSubjects([
      
      ...querySnapshot.docs.map(doc => ({ id: doc.id, name: doc.data().name }))
    ]);
  };

  const fetchCategories = async () => {
    if (selectedSubject === 'all') {
      setCategories([]);
      return;
    }
    const categoriesRef = collection(db, `subjects/${selectedSubject}/1stCategories`);
    const q = query(categoriesRef, orderBy('createdAt', 'asc'));
    const querySnapshot = await getDocs(q);
    setCategories([
      { id: 'all', name: 'All' },
      ...querySnapshot.docs.map(doc => ({ id: doc.id, name: doc.data().name }))
    ]);
  };

  const fetchSubCategories = async () => {
    if (selectedCategory === 'all') {
      setSubCategories([]);
      return;
    }
    const subCategoriesRef = collection(db, `subjects/${selectedSubject}/1stCategories/${selectedCategory}/2ndCategories`);
    const q = query(subCategoriesRef, orderBy('createdAt', 'asc'));
    const querySnapshot = await getDocs(q);
    setSubCategories([
      { id: 'all', name: 'All' },
      ...querySnapshot.docs.map(doc => ({ id: doc.id, name: doc.data().name }))
    ]);
  };

  const fetchSubSubCategories = async () => {
    if (selectedSubCategory === 'all') {
      setSubSubCategories([]);
      return;
    }
    const subSubCategoriesRef = collection(db, `subjects/${selectedSubject}/1stCategories/${selectedCategory}/2ndCategories/${selectedSubCategory}/3rdCategories`);
    const q = query(subSubCategoriesRef, orderBy('createdAt', 'asc'));
    const querySnapshot = await getDocs(q);
    setSubSubCategories([
      { id: 'all', name: 'All' },
      ...querySnapshot.docs.map(doc => ({ id: doc.id, name: doc.data().name }))
    ]);
  };

  const fetchCategoryName = async (categoryId) => {
    try {
      const firstCategoriesRef = collection(db, `subjects/${selectedSubject}/1stCategories`);
      const firstCategoriesQuery = query(firstCategoriesRef, orderBy('createdAt', 'asc'));
      const firstCategoriesSnapshot = await getDocs(firstCategoriesQuery);
  
      for (const firstDoc of firstCategoriesSnapshot.docs) {
        const firstDocName = firstDoc.data().name;
  
        if (firstDoc.id === categoryId) {
          return firstDocName;
        }
  
        const secondCategoriesRef = collection(firstDoc.ref, '2ndCategories');
        const secondCategoriesQuery = query(secondCategoriesRef, orderBy('createdAt', 'asc'));
        const secondCategoriesSnapshot = await getDocs(secondCategoriesQuery);
  
        for (const secondDoc of secondCategoriesSnapshot.docs) {
          const secondDocName = secondDoc.data().name;
  
          if (secondDoc.id === categoryId) {
            return `${firstDocName} - ${secondDocName}`;
          }
  
          const thirdCategoriesRef = collection(secondDoc.ref, '3rdCategories');
          const thirdCategoriesQuery = query(thirdCategoriesRef, orderBy('createdAt', 'asc'));
          const thirdCategoriesSnapshot = await getDocs(thirdCategoriesQuery);
  
          for (const thirdDoc of thirdCategoriesSnapshot.docs) {
            if (thirdDoc.id === categoryId) {
              return `${firstDocName} - ${secondDocName} - ${thirdDoc.data().name}`;
            }
          }
        }
      }
  
      return categoryId;
    } catch (error) {
      console.error("Error fetching category name:", error);
      return categoryId;
    }
  };

  const fetchQuizzes = async () => {
    let q;
    if (selectedSubSubCategory === 'all') {
      const subSubCategorySnapshot = await getDocs(collection(db, `subjects/${selectedSubject}/1stCategories/${selectedCategory}/2ndCategories/${selectedSubCategory}/3rdCategories`), orderBy('createdAt', 'asc'));
      const subSubCategoryIds = subSubCategorySnapshot.docs.map(doc => doc.id);
      q = query(collection(db, `subjects/${selectedSubject}/quizzes`), where('category', 'in', subSubCategoryIds), orderBy('num', sortOrder));
    } else if (selectedSubCategory === 'all') {
      const subCategorySnapshot = await getDocs(collection(db, `subjects/${selectedSubject}/1stCategories/${selectedCategory}/2ndCategories`), orderBy('createdAt', 'asc'));
      const subCategoryIds = subCategorySnapshot.docs.map(doc => doc.id);
      const subSubCategoryPromises = subCategoryIds.map(subCategoryId =>
        getDocs(collection(db, `subjects/${selectedSubject}/1stCategories/${selectedCategory}/2ndCategories/${subCategoryId}/3rdCategories`), orderBy('createdAt', 'asc'))
      );
      const subSubCategorySnapshots = await Promise.all(subSubCategoryPromises);
      const subSubCategoryIds = subSubCategorySnapshots.flatMap(snapshot => snapshot.docs.map(doc => doc.id));
      q = query(collection(db, `subjects/${selectedSubject}/quizzes`), where('category', 'in', [...subCategoryIds, ...subSubCategoryIds]), orderBy('num', sortOrder));
    } else if (selectedCategory === 'all') {
      q = query(collection(db, `subjects/${selectedSubject}/quizzes`), orderBy('num', sortOrder));
    } else {
      q = query(collection(db, `subjects/${selectedSubject}/quizzes`), where('category', '==', selectedSubSubCategory || selectedSubCategory || selectedCategory), orderBy('num', sortOrder));
    }
    const querySnapshot = await getDocs(q);
    const quizzesData = await Promise.all(querySnapshot.docs.map(async doc => {
      const quizData = doc.data();
      const categoryName = await fetchCategoryName(quizData.category);
      return {
        id: doc.id,
        ...quizData,
        categoryName
      };
    }));
    setQuizzes(quizzesData);
  };

  const handleDelete = async (id) => {
    await deleteDoc(doc(db, `subjects/${selectedSubject}/quizzes`, id));
    fetchQuizzes();
  };

  const handleEdit = (quiz) => {
    navigate('/quizManagement', { state: { quiz: quiz, isEditing: true } });
  };

  const handleSortOrderChange = (event) => {
    setSortOrder(event.target.value);
  };

  return (
    <div className="quiz-list-container">
      <div className="quiz-list">
        <h1>문제 리스트</h1>
        <div className="navbar">
          {subjects.map(subject => (
            <button
              key={subject.id}
              className={`nav-item ${selectedSubject === subject.id ? 'selected' : ''}`}
              onClick={() => {
                setSelectedSubject(subject.id);
                setSelectedCategory('');
                setSelectedSubCategory('');
                setSelectedSubSubCategory('');
              }}
            >
              {subject.name}
            </button>
          ))}
        </div>
        {selectedSubject && (
          <div className="navbar">
            {categories.map(category => (
              <button
                key={category.id}
                className={`nav-item ${selectedCategory === category.id ? 'selected' : ''}`}
                onClick={() => setSelectedCategory(category.id)}
              >
                {category.name}
              </button>
            ))}
          </div>
        )}

        {subCategories.length > 0 && (
          <>
            <div className="label">중간 카테고리 선택</div>
            <div className="navbar">
              {subCategories.map(category => (
                <button
                  key={category.id}
                  className={`nav-item ${selectedSubCategory === category.id ? 'selected' : ''}`}
                  onClick={() => setSelectedSubCategory(category.id)}
                >
                  {category.name}
                </button>
              ))}
            </div>
          </>
        )}
        {subSubCategories.length > 0 && (
          <>
            <div className="label">하위 카테고리 선택</div>
            <div className="navbar">
              {subSubCategories.map(category => (
                <button
                  key={category.id}
                  className={`nav-item ${selectedSubSubCategory === category.id ? 'selected' : ''}`}
                  onClick={() => setSelectedSubSubCategory(category.id)}
                >
                  {category.name}
                </button>
              ))}
            </div>
          </>
        )}
                <div className="sort-order">
          <label htmlFor="sortOrder">정렬:</label>
          <select id="sortOrder" value={sortOrder} onChange={handleSortOrderChange}>
            <option value="asc">오름차순</option>
            <option value="desc">내림차순</option>
          </select>
          <p></p>
        </div>
        <div className="quiz-items">
          {quizzes.map((quiz) => (
            <div key={quiz.id} className="quiz-item">
              <div className="quiz-info">
                <div className="quiz-number">{quiz.num.toString().padStart(3, '0')}</div>
                <div className="quiz-question">{quiz.question.length > 60 ? `${quiz.question.substring(0, 62)}...` : quiz.question}</div>
                <div className="quiz-category">{quiz.categoryName}</div>
              </div>
              <div className="quiz-actions">
                <button onClick={() => handleEdit(quiz)}>수정</button>
                <button onClick={() => handleDelete(quiz.id)}>삭제</button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default QuizList;
