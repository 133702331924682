import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { db } from '../firebaseConfig';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './SubjectSelecting.css';

const SubjectSelecting = () => {
  const [selectedSubject, setSelectedSubject] = useState('');
  const [subjects, setSubjects] = useState([]);
  const navigate = useNavigate();
  const storage = getStorage();

  useEffect(() => {
    const fetchSubjects = async () => {
      const subjectsCollection = collection(db, 'subjects');
      const subjectsSnapshot = await getDocs(subjectsCollection);
      
      const subjectsList = await Promise.all(subjectsSnapshot.docs.map(async doc => {
        const imageName = `${doc.id}.jpg`;
        const imageRef = ref(storage, `subject_images/${imageName}`);

        try {
          const imageUrl = await getDownloadURL(imageRef);
          return {
            id: doc.id,
            name: doc.data().name,
            image: imageUrl
          };
        } catch (error) {
          console.error(`Error fetching image for ${doc.data().name}:`, error);
          return {
            id: doc.id,
            name: doc.data().name,
            image: '/path/to/default-image.jpg'
          };
        }
      }));
      
      setSubjects(subjectsList);
      if (subjectsList.length > 0) {
        setSelectedSubject(subjectsList[0].id); // Set the first subject as selected by default
      }
    };

    fetchSubjects();
  }, [storage]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (index) => setSelectedSubject(subjects[index]?.id || '') // Update selected subject on swipe
  };

  const handleSubjectSelect = () => {
    navigate('/quiz-selecting', { state: { subject: selectedSubject } });
  };

  return (
    <div className="subject-selecting">
      <h1>과목 선택</h1>
      <Slider {...settings}>
        {subjects.map(subject => (
          <div key={subject.id} className="subject-slide">
            <div className="subject-image" style={{ backgroundImage: `url(${subject.image})` }}>
              <h2>{subject.name}</h2>
            </div>
          </div>
        ))}
      </Slider>
      <button onClick={handleSubjectSelect} className="start-button">
        시작하기
      </button>
    </div>
  );
};

export default SubjectSelecting;
